.App {
    height: 100%;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}


#tournamentContainer {
    overflow-x: scroll;
    overflow-y: hidden;
}


@font-face {
    font-family: 'BankGothic';
    src: local('BankGothic'), url(./fonts/BankGothic.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
